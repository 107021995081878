<template>
  <div class="app-container">
    <navBar :title="'考勤'" @handleGoBack="handleGoBack">
      <template v-slot:icon2>
        <van-popover
            v-model="showPopover"
            placement="bottom-end"
            trigger="click"
            :actions="actions"
            @select="onSelect"
        >
          <template #reference>
            <i class="iconfont icon-gengduo" style="font-size: 20px"></i>
          </template>
        </van-popover>
      </template>
    </navBar>
    <div class="flex content1">
      <div class="left-con flex-ver-cen">
        <img :src="avatar" alt=""/>
        <div class="name-wrap">
          <div class="name">{{ name }}</div>
          <div class="company">{{ companyName }}</div>
        </div>
      </div>
      <div class="right-con" @click="handleApply" v-if="this.$auth.hasPermi('hr:checkwork:add')">
        <i class="iconfont icon-calendar daka-icon"></i>
        <div class="desc">补卡申请</div>
      </div>
    </div>

    <div class="content2">
      <div class="flex">
        <div class="left flex">
          <img src="../../assets/images/sun_icon.png" alt=""/>
          <div class="desc">您好，{{ name }}</div>
        </div>
        <div class="right flex">
          <img src="../../assets/images/li_icon.png" alt=""/>
          <div class="desc">{{ currentDate }} {{ week }}</div>
        </div>
      </div>
    </div>

    <div class="tranferDiv" @click="handleTranferType"
         v-if="this.$auth.hasPermi('hr:checkwork:add') && this.$auth.hasPermi('hr:checkwork:out')">
      切换{{ tranferType == 0 ? '外勤' : '坐班' }}打卡
    </div>

    <span v-if="!allowAgainClock" style="margin-left: 18%;font-size: 16px;color: red">{{ noAgainClockTip }}</span>

    <div v-if="this.$auth.hasPermi('hr:checkwork:add') && tranferType == 0">
      <van-field
          v-model="clockRemark"
          v-if="showOn"
          rows="1"
          autosize
          type="textarea"
          style="margin-top: 20px"
          placeholder="打卡备注(不必填)"
      />

      <template v-if="!showNoAuthor">
        <div
            class="content3"
            v-swipeleft="(e) => touch('左滑', e)"
            v-show="currentObj.currentClockType == 0 && !isClockUp"
        >
          <div class="flex top">
            <img src="../../assets/images/small_circle.png" alt=""/>
            <div class="work-time">上班打卡 {{ currentObj.upTime }}</div>
          </div>
          <div class="center">
            <div class="desc">左滑切换至外勤打卡</div>

            <!-- 上班打卡和迟到打卡   	打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡 -->
            <!-- handleDaka(1, 0):第一个参数打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡    第二个参数workType	打卡类型 0坐班上下班;1外勤上下班;2外勤多卡   -->
            <div class="daka-wrap" v-show="showOn" @click="handleDaka(0, 0)">
              <img :src="dakaBgImg" alt=""/>
              <div class="on-time">{{ timeDesc }}</div>
              <div class="time">{{ currentTime }}</div>
            </div>
            <div class="flex-hor-ver-cen address" v-show="showOn">
              <template
                  v-if="
                currentAddressDetail == '未在打卡范围内，禁止打卡' ||
                currentAddressDetail == '未连WiFi，禁止打卡'
              "
              >
                <van-icon name="warning-o" color="#F7AD00"/>
              </template>
              <template v-else>
                <van-icon name="passed"/>
              </template>
              <div class="address-desc mrg-l-10">{{ currentAddressDetail }}</div>
            </div>

            <!-- 外勤打卡 -->
            <div class="daka-wrap" v-show="!showOn" @click="handleFieldPunch(0)">
              <img :src="dakaBgImg" alt=""/>
              <div class="on-time">外勤打卡</div>
              <div class="time">{{ currentTime }}</div>
            </div>
            <div class="flex-hor-ver-cen address" v-show="!showOn">
              <van-icon name="warning-o" color="#F7AD00"/>
              <div class="address-desc mrg-l-10">未在打卡范围内</div>
            </div>
          </div>
          <div class="flex-ver-cen">
            <img class="small-circle" src="../../assets/images/small_circle.png" alt=""/>
            <div class="work-time">下班打卡 {{ currentObj.downTime }}</div>
          </div>
        </div>

        <div
            class="content3 content-pm"
            v-show="currentObj.currentClockType == 1 || isClockUp"
            v-swipeleft="(e) => touch('左滑', e)"
        >
          <div class="top">
            <div class="line"></div>

            <div class="flex">
              <img src="../../assets/images/small_circle.png" alt=""/>
              <div class="work-time">上班打卡 {{ currentObj.upTime }}</div>
              <template v-if="userDakaList.length == 0">
                <div class="tag tag2">缺卡</div>
              </template>
              <template v-if="userDakaList.length > 0">
                <div class="tag" v-if="userDakaList[0].clockTime > currentObj.upTime">迟到</div>

                <div class="tag tag3" v-if="userDakaList[0].clockTime <= currentObj.upTime">正常</div>
                <!-- <div class="tag tag4">早退</div> -->
              </template>
            </div>

            <div class="daka-time">
              打卡时间
              {{ onWorkTime }}
            </div>
            <div class="wifi">
              <i class="iconfont icon-wifi mrg-r-10"></i>{{ currentObj.officeAddress }}
            </div>

            <div class="flex-ver-cen mrg-t-100">
              <img class="small-circle" src="../../assets/images/small_circle.png" alt=""/>
              <div class="work-time">下班打卡 {{ currentObj.downTime }}</div>
            </div>
            <template v-if="isClockDown">
              <div class="daka-time">
                打卡时间
                {{ downWorkTime }}
              </div>
              <div class="wifi">
                <i class="iconfont icon-wifi mrg-r-10"></i>{{ currentObj.officeAddress }}
              </div>
            </template>
          </div>
          <div class="center center2" v-if="showOn && !isClockDown">
            <!-- handleDaka(1, 0):第一个参数打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡    第二个参数workType	打卡类型 0坐班上下班;1外勤上下班;2外勤多卡   -->
            <div class="daka-wrap" @click="handleDaka(1, 0)">
              <img src="../../assets/images/circle.png" alt=""/>

              <div class="on-time">下班打卡</div>
              <div class="time">{{ currentTime }}</div>
            </div>

            <div class="flex-hor-ver-cen address">
              <template
                  v-if="
                currentAddressDetail == '未在打卡范围内，禁止打卡' ||
                currentAddressDetail == '未连WiFi，禁止打卡'
              "
              >
                <van-icon name="warning-o" color="#F7AD00"/>
              </template>
              <template v-else>
                <van-icon name="passed"/>
              </template>
              <div class="address-desc mrg-l-10">{{ currentAddressDetail }}</div>
            </div>
          </div>

          <div class="center center2" v-if="!showOn && !isClockDown">
            <!-- handleDaka(1, 0):第一个参数打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡    第二个参数workType	打卡类型 0坐班上下班;1外勤上下班;2外勤多卡   -->

            <div class="daka-wrap" @click="handleFieldPunch(1)">
              <img src="../../assets/images/waiqin.png" alt=""/>
              <div class="on-time">外勤打卡</div>
              <div class="time">{{ currentTime }}</div>
            </div>

            <div class="flex-hor-ver-cen address">
              <van-icon name="warning-o" color="#F7AD00"/>
              <div class="address-desc mrg-l-10">未在打卡范围内</div>
            </div>
          </div>
        </div>
      </template>
      <!-- 当获取findByCurrentUser接口时，无数据返回时显示没有设置打卡规则 -->
      <template v-else>
        <div class="no-author">
          <van-empty
              :image="require('../../assets/images/empty.png')"
              description="您没有打卡规则，无法打卡，请联系管理员设置打卡规则"
          />
        </div>
      </template>
    </div>

    <!-- =================================外勤多卡 开始================================= -->
    <div class="wqClass" v-if="this.$auth.hasPermi('hr:checkwork:out') && tranferType == 1">
      <div class="center center2">
        <!-- handleDaka(1, 0):第一个参数打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡    第二个参数workType	打卡类型 0坐班上下班;1外勤上下班;2外勤多卡   -->

        <div class="daka-wrap" @click="handleFieldPunch(1)">
          <img src="../../assets/images/waiqin.png" alt=""/>
          <div class="on-time">外勤打卡</div>
          <div class="time">{{ currentTime }}</div>
        </div>
      </div>
    </div>
    <!-- =================================外勤多卡 结束================================= -->

    <baidu-map
        class="map"
        id="map"
        :ak="ak"
        :center="center"
        :zoom="zoom"
    ></baidu-map>

    <!-- 外勤打卡弹窗 -->
    <van-dialog v-model="showWQ" title="外勤打卡" show-cancel-button @confirm="tranferType == 0?handleWq():handleWq2()"
                style="width: 90%;height: 350px">
      <div class="wq-content">
        <div class="time">打卡时间 {{ currentTime }}</div>
        <div class="address">打卡地点:{{ currentAddress }}</div>
        <div class="uploader">
          <van-uploader v-model="fileList" :max-count="2" preview-size="90px" capture="camera"/>
        </div>

        <van-field
            v-model="remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请备注外勤打卡原因（50字内）"
            show-word-limit
        />
      </div>
    </van-dialog>

    <van-overlay :show="overlayShow">
      <div style=" display: flex;align-items: center;justify-content: center;height: 100%;" @click.stop>
        <van-loading :show="loadingShow" color="#0094ff" size="30px" vertical>正在获取打卡信息...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  changeToCurrentDay,
  getCurrentMonthDay2,
  getCurrentTime,
  getCurrentWeek,
  GetDistance,
  strToDate,
} from '@/utils/ruoyi';
import {
  checkwork,
  fetchByUserId,
  fetchCurrentDaKaRule,
  fetchIsClockDown,
  fetchIsClockUp,
  getIp,
} from '@/api/hr/hrAttendance';
import {submiteUpload} from '@/api/system/config';
import {findAddressByLngLatTDT} from "@/api/system/ymxx";
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import {AK_KEY} from '@/views/constant';

export default {
  components: {BaiduMap},

  data() {
    return {
      //打卡备注
      clockRemark: '',
      //打卡备注
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        {text: '统计', className: 'attendance-item-actions', path: '/attendance/statistics'}
      ],
      showAm: true,
      name: localStorage.getItem('realname'),
      week: getCurrentWeek(),
      currentDate: getCurrentMonthDay2(),
      currentTime: getCurrentTime(),
      companyName: localStorage.getItem('companyName'),
      avatar: this.$store.getters.avatar,
      dakaBgImg: require('../../assets/images/circle.png'),
      showOn: true,
      timeDesc: '上班打卡',
      map: null,
      currentObj: {},
      validRange: true,
      ip: null, // 当前用户ip
      distance: null, // 当前位置跟打卡具体位置之间的距离
      currentAddressDetail: '--',
      clockLonLat: null, // 	打卡地经纬度
      clockType: 0, // 打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡
      workType: 0, // 打卡类型 0坐班上下班;1外勤上下班;2外勤多卡
      currentClockType: 0, // 上班打卡是0  下班打卡是1
      userDakaList: [],
      mapTimer: null,
      showWQ: false,
      remark: null,
      showNoAuthor: true,
      isClockDown: false,
      isClockUp: false,
      onWorkTime: '--:--:--',
      downWorkTime: '--:--:--',
      ak: AK_KEY,
      center: {lng: 117.12639941, lat: 36.6565542},
      zoom: 15,
      testLatLng: '',
      //遮罩
      overlayShow: true,
      //加载
      loadingShow: true,
      //附件列表
      fileList: [],
      //外勤打卡类型 0上班卡 1下班卡
      wqClockType: 0,
      //时间循环对象(当前时间)
      currentTimeObj: undefined,
      //时间循环对象(ip)
      ipObj: undefined,
      //打卡类型 0坐班/外勤 上下班卡； 1外勤多卡
      tranferType: 0,
      //当前打卡地点
      currentAddress: '',
      //是否允许再次打卡
      allowAgainClock: true,
      //多少分钟内不能打卡
      minuteLimit: 15,
      //不允许打卡的提示
      noAgainClockTip: '* 您15分钟内已打卡，无需再次打卡'
    };
  },

  mounted() {
    /*console.log('aaaa',navigator.userAgent)
    console.log('bbbb',navigator.appName + "/" + navigator.appVersion)
    console.log('cccc',navigator.platform)
    this.$toast({
      message: navigator.userAgent+'====='+navigator.appName+'====='+navigator.appVersion+'====='+navigator.platform,
      icon: 'fail',
    });*/

    let clockTranferType = localStorage.getItem('clockTranferType')
    this.tranferType = clockTranferType && clockTranferType != 'undefined' ? clockTranferType : 0

    //有上下班打卡才掉以下接口
    if (this.$auth.hasPermi('hr:checkwork:add')) {
      this.actions.push(
          {text: '设置', className: 'attendance-item-actions', path: '/attendance/setting'},
          {text: '补卡记录', className: 'attendance-item-actions', path: '/attendance/cardRecord'}
      )

      this.getDakaRule();

      //获取IP
      this.findIp();

      this.getUserInfoById();
      this.getIsClockUp();
      this.getIsClockDown();

      setTimeout(() => {
        //获取当前定位
        this.getCurrentPoint()
      }, 1000)
    } else {
      //如果没有上下班打卡权限，展示外勤多卡
      this.tranferType = 1

      setTimeout(() => {
        //获取当前定位
        this.getCurrentPointWQ()
      }, 500)

      setTimeout(() => {
        //获取当前定位
        this.getCurrentPointWQ()
      }, 6000)
    }

    this.currentTimeObj = setInterval(() => {
      this.currentTime = getCurrentTime();
    }, 500);

    //检测遮罩和加载是否一直持续
    this.continueLoad()

    //是否允许再次打卡
    this.againClock()
  },

  destroyed() {
    if (this.mapTimer) {
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }
    if (this.currentTimeObj) {
      clearInterval(this.currentTimeObj);
      this.currentTimeObj = null;
    }
    if (this.ipObj) {
      clearInterval(this.ipObj);
      this.ipObj = null;
    }
  },

  methods: {
    //检测遮罩和加载是否一直持续
    continueLoad() {
      let that = this
      setTimeout(() => {
        if (that.overlayShow == true && that.loadingShow == true) {
          that.overlayShow = false
          that.loadingShow = false
          /*if (that.currentAddress == undefined || that.currentAddress == '') {
              location.reload();
          }*/
        }
      }, 20000)
    },

    //查询IP
    async findIp() {
      let result = await getIp();
      this.ip = result.data;
      //每3秒获取一次IP
      this.ipObj = await setInterval(async () => {
        let result = await getIp();
        this.ip = result.data;
      }, 2000);
    },

    async getIsClockDown() {
      const result = await fetchIsClockDown();
      this.isClockDown = result.data;
    },

    async getIsClockUp() {
      const result = await fetchIsClockUp();
      this.isClockUp = result.data;
    },

    handleRule(time = '17:30:10') {
      const {isAddress, isWifi, validRange, officeAddress, wifiInfoList, currentClockType} =
          this.currentObj;
      // currentClockType 0是上班打卡   1是下班打卡
      /** 测试数据开始 **/
      // const isAddress = false,
      //   isWifi = true,
      //   validRange = 8000,
      //   officeAddress = '金秀广场',
      //   wifiInfoList = [
      //     { wifiName: '测试WiFi', wifiIp: '39.82.7.2008' },
      //     { wifiName: '测试WiFi2', wifiIp: '39.82.7.199' },
      //   ],
      //   currentClockType = 1;
      /**  测试数据结束**/
      // isAddress,isWif他俩都是true，需要同时满足才能打卡
      if (isAddress && isWifi) {
        // 只需要判断是否在有效地址内，并且后端返回的IP是否包含本地IP
        // 前端校验一下，后端返回的IP数组中是否含有本地ip。含有本地IP才让打卡，不含不让
        const isIncludeIp = wifiInfoList.map((ele) => ele.wifiIp).includes(this.ip);
        if (this.distance <= validRange && this.currentTime <= time && isIncludeIp) {
          // 距离在有效范围内，含有本地IP，并且时间在上班时间之前，则为正常的上班打卡
          this.dakaBgImg = require('../../assets/images/circle.png');
          this.timeDesc = currentClockType == 0 ? '上班打卡' : '早退打卡';
          this.currentAddressDetail = `已进入打卡范围&已连接wifi：${officeAddress}`;
        } else if (this.distance <= validRange && this.currentTime > time && isIncludeIp) {
          // 距离在有效范围内，含有本地IP，并且时间在上班时间之后，则为迟到打卡
          this.dakaBgImg = require('../../assets/images/chidao.png');
          this.timeDesc = currentClockType == 0 ? '迟到打卡' : '下班打卡';
          this.currentAddressDetail = `已进入打卡范围&已连接wifi：${officeAddress}`;
        } else {
          if (this.currentTime <= time) {
            this.timeDesc = currentClockType == 0 ? '上班打卡' : '早退打卡';
            this.dakaBgImg = require('../../assets/images/circle.png');
          } else {
            this.dakaBgImg = require('../../assets/images/chidao.png');
            this.timeDesc = currentClockType == 0 ? '迟到打卡' : '下班打卡';
          }
          if (!isIncludeIp) {
            this.currentAddressDetail = '未连WiFi，禁止打卡';
            /*let workType = this.getNetworkType()
            if (workType == 'wifi') {
                //如果IP不符，并且当前已连WiFi，将当前IP改为规则中的IP，让用户打卡(如果不在范围内仍不能打卡)
                this.ip = wifiInfoList[0].wifiIp

                clearInterval(this.ipObj);
                this.ipObj = null;
                this.handleRule(time);
            } else {
                this.currentAddressDetail = '未连WiFi，禁止打卡';
            }*/
          }
          if (this.distance > validRange) {
            this.currentAddressDetail = '未在打卡范围内，禁止打卡';
          }
        }
      } else if (isAddress && !isWifi) {
        // 只需要判断是否在有效地址内即可
        if (this.distance <= validRange && this.currentTime <= time) {
          // 距离在有效范围内，并且时间在上班时间之前，则为正常的上班打卡
          this.dakaBgImg = require('../../assets/images/circle.png');
          this.timeDesc = currentClockType == 0 ? '上班打卡' : '早退打卡';
          this.currentAddressDetail = `已进入打卡范围：${officeAddress}`;
        } else if (this.distance <= validRange && this.currentTime > time) {
          // 距离在有效范围内，并且时间在上班时间之后，则为迟到打卡
          this.dakaBgImg = require('../../assets/images/chidao.png');
          this.timeDesc = currentClockType == 0 ? '迟到打卡' : '下班打卡';
          this.currentAddressDetail = `已进入打卡范围：${officeAddress}`;
        } else {
          if (this.currentTime <= time) {
            this.timeDesc = currentClockType == 0 ? '上班打卡' : '早退打卡';
            this.dakaBgImg = require('../../assets/images/circle.png');
          } else {
            this.dakaBgImg = require('../../assets/images/chidao.png');
            this.timeDesc = currentClockType == 0 ? '迟到打卡' : '下班打卡';
          }
          this.currentAddressDetail = '未在打卡范围内，禁止打卡';
        }
      } else if (!isAddress && isWifi) {
        // 只能用wifi打卡，不能用地址打卡

        // 前端校验一下，后端返回的IP数组中是否含有本地ip。含有本地IP才让打卡，不含不让
        const isIncludeIp = wifiInfoList.map((ele) => ele.wifiIp).includes(this.ip);
        if (this.currentTime <= time && isIncludeIp) {
          this.dakaBgImg = require('../../assets/images/circle.png');
          this.timeDesc = currentClockType == 0 ? '上班打卡' : '早退打卡';
          this.currentAddressDetail = '已连接WiFi';
        } else if (this.currentTime > time && isIncludeIp) {
          this.dakaBgImg = require('../../assets/images/chidao.png');
          this.timeDesc = currentClockType == 0 ? '上班打卡' : '下班打卡';
          this.currentAddressDetail = '已连接WiFi';
        } else {
          if (this.currentTime <= time) {
            this.timeDesc = currentClockType == 0 ? '上班打卡' : '早退打卡';
            this.dakaBgImg = require('../../assets/images/circle.png');
          } else {
            this.dakaBgImg = require('../../assets/images/chidao.png');
            this.timeDesc = currentClockType == 0 ? '迟到打卡' : '下班打卡';
          }
          this.currentAddressDetail = '未连WiFi，禁止打卡';
        }
      }

      //去掉遮罩和加载
      this.overlayShow = false
      this.loadingShow = false
    },

    async handleDaka(clockType, workType) {
      if (!this.allowAgainClock) {
        //不允许再次打卡
        this.$toast({
          message: this.noAgainClockTip,
          icon: 'fail',
        });
        return;
      }

      if (
          this.currentAddressDetail == '未在打卡范围内，禁止打卡' ||
          this.currentAddressDetail == '未连WiFi，禁止打卡'
      ) {
        this.$toast({
          message: '禁止打卡',
          icon: 'fail',
        });
        return;
      }
      if (this.currentAddressDetail == '--') {
        this.$toast({
          message: '获取打卡信息失败，请重新刷新重试',
          icon: 'fail',
        });
        return;
      }

      let params = this.objectToFormData({
        clockLonLat: this.clockLonLat,
        clockType, // 打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡
        workType, // 打卡类型 0坐班上下班;1外勤上下班;2外勤多卡
        clockAddress: this.currentAddress,
        remark: this.clockRemark //打卡备注
      });

      const result = await checkwork(params);
      if (result.code == 200) {
        //记录打卡时间
        this.clockTimeSave()

        const {currentClockType, downTime} = this.currentObj;
        if (currentClockType == 1 && this.currentTime < downTime) {
          this.$toast({
            message: '早退打卡成功',
            icon: 'success',
          });
        } else {
          this.$toast({
            message: '打卡成功',
            icon: 'success',
          });
        }

        this.clockRemark = ''

        await this.getDakaRule();

        await this.getIsClockUp();
        await this.getIsClockDown();
      }
    },

    // 外勤打卡
    handleFieldPunch(clockType) {
      this.showWQ = !this.showWQ;
      this.wqClockType = clockType;
    },

    //上下班外勤打卡提交
    async handleWq() {
      if (!this.allowAgainClock) {
        //不允许再次打卡
        this.$toast({
          message: this.noAgainClockTip,
          icon: 'fail',
        });
        return;
      }

      let that = this;
      let params = this.objectToFormData({
        clockLonLat: this.clockLonLat,
        clockType: that.wqClockType, // 打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡
        workType: 1, // 打卡类型 0坐班上下班;1外勤上下班;2外勤多卡
        clockAddress: this.currentAddress,
        remark: this.remark,
      });

      const result = await checkwork(params);
      // hr_checkwork
      if (result.code == 200) {
        //记录打卡时间
        this.clockTimeSave()

        const {currentClockType, downTime} = this.currentObj;
        if (currentClockType == 1 && this.currentTime < downTime) {
          this.$toast({
            message: '早退打卡成功',
            icon: 'success',
          });
        } else {
          this.$toast({
            message: '打卡成功',
            icon: 'success',
          });
        }
        this.showWQ = false;

        if (this.fileList) {
          this.fileList.forEach(item => {
            let params = new FormData();
            params.append('file', item.file);
            params.append('businessType', 'hr_checkwork');
            params.append('businessId', result.data);
            submiteUpload(params);
          })
          this.fileList = []
        }
        await this.getDakaRule();

        await this.getIsClockUp();
        await this.getIsClockDown();
      }
    },

    //外勤多卡 提交
    async handleWq2() {
      if (!this.allowAgainClock) {
        //不允许再次打卡
        this.$toast({
          message: this.noAgainClockTip,
          icon: 'fail',
        });
        return;
      }
      if (!this.fileList || this.fileList.length == 0) {
        this.$toast({
          message: '请上传照片',
          icon: 'fail',
        });
        return;
      }

      let params = this.objectToFormData({
        clockLonLat: this.clockLonLat,
        clockType: 3, // 打卡类型 0上班打卡;1下班打卡;2补卡;3外勤多卡
        workType: 1, // 打卡类型 0坐班上下班;1外勤上下班;2外勤多卡
        clockAddress: this.currentAddress,
        remark: this.remark,
      });

      const result = await checkwork(params);
      // hr_checkwork
      if (result.code == 200) {
        //记录打卡时间
        this.clockTimeSave()

        this.$toast({
          message: '打卡成功',
          icon: 'success',
        });

        if (this.fileList) {
          this.fileList.forEach(item => {
            let params = new FormData();
            params.append('file', item.file);
            params.append('businessType', 'hr_checkwork');
            params.append('businessId', result.data);
            submiteUpload(params);
          })
          this.fileList = []
        }
      }
    },

    //记录本次打卡时间
    clockTimeSave() {
      localStorage.setItem('HrClockTime', changeToCurrentDay())
      this.allowAgainClock = false
    },

    //判断是否允许再次打卡
    againClock() {
      //上次打卡时间
      let oldClockTime = localStorage.getItem('HrClockTime')
      if (oldClockTime) {
        oldClockTime = strToDate(oldClockTime).getTime()

        //本次打卡时间
        let newClockTime = new Date().getTime()

        if ((newClockTime - oldClockTime) / 1000 > (this.minuteLimit * 60)) {
          this.allowAgainClock = true
        } else {
          this.allowAgainClock = false
        }
        this.$forceUpdate()
      }

    },

    async getDakaRule() {
      const result = await fetchCurrentDaKaRule();
      this.currentObj = result.data;
      // this.currentObj.downTime = '17.30.01';
      // this.currentObj.currentClockType = 1;
      // this.currentObj.upTime = '08:30:00';
      if (!result.data) {
        this.actions = [
          {text: '设置', className: 'attendance-item-actions', path: '/attendance/setting'},
          {
            text: '补卡记录',
            className: 'attendance-item-actions',
            path: '/attendance/cardRecord',
          },
        ];
        this.showNoAuthor = true;
        return;
      }
      this.showNoAuthor = false;
      // 8秒钟掉一次位置信息
      this.mapTimer = setInterval(() => {
        //DOM初始化完成进行地图初始化
        this.getCurrentPoint();
      }, 8000);

      // this.initMap2();
      //DOM初始化完成进行地图初始化
      // this.initMap();
      // this.mapTimer = setInterval(() => {
      //   //DOM初始化完成进行地图初始化
      //   this.initMap();
      // }, 5000);
    },

    //查询当前经纬度--正常上下班打卡
    getCurrentPoint() {
      if (navigator.geolocation) {
        let _this = this
        navigator.geolocation.getCurrentPosition(async function (position) {
          let lng = position.coords.longitude
          let lat = position.coords.latitude
          console.log('上下班打卡打卡您的位置：' + lng + ',' + lat);

          const {upTime, currentClockType, downTime, officeLonLat} = _this.currentObj;

          _this.clockLonLat = `${lng},${lat}`;

          let result = await findAddressByLngLatTDT({lngLat: lng + ',' + lat})
          _this.currentAddress = result.data;
          await localStorage.setItem(
              'centerPoint',
              JSON.stringify({
                'clockLonLat': lng + ',' + lat,
                'address': _this.currentAddress
              })
          );

          let officeLonLatSplit = await officeLonLat.split(',');
          let lat2 = officeLonLatSplit[1];
          let lng2 = officeLonLatSplit[0];

          //计算距离
          _this.distance = await GetDistance(lat, lng, lat2, lng2);

          if (currentClockType == 0) {
            // 0 是上班打卡
            _this.handleRule(upTime);
          } else {
            // 1 是下班打卡
            _this.handleRule(downTime);
          }
        }, function (error) {
          _this.getLngLatByBaiDu1()
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert('用户拒绝对获取地理位置的请求。')
              break;
            case error.POSITION_UNAVAILABLE:
              alert('位置信息是不可用的。')
              break;
            case error.TIMEOUT:
              console.log('原生请求用户地理位置超时。')
              break;
            case error.UNKNOWN_ERROR:
              alert('未知错误。')
              break;
          }
        }, {timeout: 5000, enableHighAccuracy: true});
      } else {
        alert('Geolocation is not supported by this browser.')
      }
    },

    //查询当前经纬度--外勤打卡
    getCurrentPointWQ() {
      if (navigator.geolocation) {
        let _this = this
        navigator.geolocation.getCurrentPosition(async function (position) {
          let lng = position.coords.longitude
          let lat = position.coords.latitude

          _this.center.lng = lng;
          _this.center.lat = lat;
          _this.clockLonLat = `${lng},${lat}`
          let result = await findAddressByLngLatTDT({lngLat: lng + ',' + lat})
          _this.currentAddress = result.data;

          //去掉遮罩和加载
          _this.overlayShow = false
          _this.loadingShow = false
        }, function (error) {
          _this.getLngLatByBaiDu2()
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert('用户拒绝对获取地理位置的请求。')
              break;
            case error.POSITION_UNAVAILABLE:
              alert('位置信息是不可用的。')
              break;
            case error.TIMEOUT:
              console.log('原生请求用户地理位置超时。')
              break;
            case error.UNKNOWN_ERROR:
              alert('未知错误。')
              break;
          }
        }, {timeout: 5000, enableHighAccuracy: true})
      }
    },

    //查询指定用户打卡记录
    async getUserInfoById() {
      const result = await fetchByUserId({
        userId: localStorage.getItem('userId'),
        day: this.currentDate,
      });
      this.userDakaList = result.data;
      if (result.data.length) {
        this.onWorkTime =
            result.data[0] && result.data[0].clockType == 0 ? result.data[0].clockTime : '--:--:--';

        this.downWorkTime =
            result.data[1] && result.data[1].clockType == 1 ? result.data[1].clockTime : '--:--:--';
      }
    },

    onSelect({path}) {
      this.$router.push({
        path,
      });
    },

    //补卡申请
    handleApply() {
      this.$router.push({
        path: '/attendance/applyCard',
      });
    },

    // 滑动事件
    touch() {
      this.dakaBgImg = require('../../assets/images/waiqin.png');
      this.showOn = !this.showOn;
      this.dakaBgImg = this.showOn
          ? require('../../assets/images/circle.png')
          : require('../../assets/images/waiqin.png');
      if (this.showOn && this.currentTime > '09:00:00') {
        this.dakaBgImg = require('../../assets/images/chidao.png');
        this.timeDesc = '迟到打卡';
      }
    },

    //返回
    handleGoBack() {
      this.$router.replace(localStorage.getItem('indexPath'));
      this.$router.go(-1)
    },

    //获取网络类型
    getNetworkType() {
      let nc = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (nc) {
        let networkType;
        switch (nc.type) {
          case 'wifi':
            networkType = 'wifi';
            break;
          case '4g':
            networkType = '4g';
            break;
          case '3g':
            networkType = '3g';
            break;
          case '3gnet':
            networkType = '3g';
            break;
          case '2g':
            networkType = '2g';
            break;
          default:
            networkType = 'other';
        }
        return networkType;
      } else {
        return 'wifi';
      }

    },

    //本地gps获取不到，通过百度地图获取地理信息 --正常上下班
    getLngLatByBaiDu1() {
      console.log('百度打卡..')
      let BMap = window.BMap;
      let map = new BMap.Map('map');
      let point = new BMap.Point(this.center.lng, this.center.lat);
      map.centerAndZoom(point, 12);
      let _this = this;
      let geolocation = new BMap.Geolocation();
      // 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          let mk = new BMap.Marker(r.point);
          map.addOverlay(mk);
          map.panTo(r.point);
          console.log('百度打卡您的位置：' + r.point.lng + ',' + r.point.lat);
          const {upTime, currentClockType, downTime} = _this.currentObj;

          _this.clockLonLat = `${r.point.lng},${r.point.lat}`;
          _this.currentAddress = `${r.address.province}${r.address.city}${r.address.district}${r.address.street}${r.address.street_number}`;
          localStorage.setItem(
              'centerPoint',
              JSON.stringify({
                'clockLonLat': r.point.lng + ',' + r.point.lat,
                'address': _this.currentAddress
              })
          );

          const result = _this.currentObj.officeLonLat.split(',');
          const lat2 = result[1];
          const lng2 = result[0];

          _this.distance = GetDistance(r.point.lat, r.point.lng, lat2, lng2);

          if (currentClockType == 0) {
            // 0 是上班打卡
            _this.handleRule(upTime);
          } else {
            // 1 是下班打卡
            _this.handleRule(downTime);
          }
        } else {
          alert('failed' + this.getStatus());
        }
      })
    },

    //本地gps获取不到，通过百度地图获取地理信息 --外勤上下班
    getLngLatByBaiDu2() {
      console.log('百度打卡..')
      let BMap = window.BMap;
      let map = new BMap.Map('map');
      let point = new BMap.Point(this.center.lng, this.center.lat);
      map.centerAndZoom(point, 12);
      let _this = this;
      let geolocation = new BMap.Geolocation();
      // 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          let mk = new BMap.Marker(r.point);
          map.addOverlay(mk);
          map.panTo(r.point);
          console.log('百度打卡您的位置：' + r.point.lng + ',' + r.point.lat);
          _this.center.lng = r.point.lng;
          _this.center.lat = r.point.lat;

          _this.clockLonLat = `${r.point.lng},${r.point.lat}`;
          _this.currentAddress = `${r.address.province}${r.address.city}${r.address.district}${r.address.street}${r.address.street_number}`;

          //去掉遮罩和加载
          _this.overlayShow = false
          _this.loadingShow = false
        } else {
          alert('failed' + this.getStatus());
        }
      })
    },

    //切换打卡类型 0坐班/外勤 上下班卡； 1外勤多卡
    handleTranferType() {
      if (this.tranferType == 0) {
        this.tranferType = 1
      } else {
        this.tranferType = 0
      }
      localStorage.setItem("clockTranferType", this.tranferType)
    }
  }
};
</script>

<style lang="scss" scoped>
#map {
  padding: 0px;
  margin: 0px;
  width: 0;
  height: 0;
  display: none;
}

.content1 {
  border-top: 13px solid #fafafa;
  border-bottom: 13px solid #fafafa;
  padding: 30px 44px;
  background: #fff;

  img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
  }

  .left-con {
    width: 530px;

    .name-wrap {
      margin-left: 20px;

      .name {
        font-weight: bold;
        color: #111111;
        font-size: 34px;
      }

      .company {
        font-size: 26px;
        font-weight: 400;
        color: #777777;
        margin-top: 10px;
      }
    }
  }

  .right-con {
    flex: 1;
    text-align: center;
    border-left: 1px solid #ececec;
    padding-left: 30px;
    cursor: pointer;

    .daka-icon {
      font-size: 34px;
      color: #111;
    }

    .desc {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      margin-top: 10px;
    }
  }
}

.content2 {
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #ececec;

  .left {
    padding: 25px 40px;
    width: 330px;
    border-right: 1px solid #ececec;

    img {
      width: 42px;
      height: 29px;
    }

    .desc {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      margin-left: 20px;
    }
  }

  .right {
    padding: 25px 40px;

    img {
      width: 26px;
      height: 26px;
    }

    .desc {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      margin-left: 20px;
    }
  }
}

.content3 {
  background: #fff;
  padding: 50px 45px;

  .top {
    justify-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 25px;
    }

    .work-time {
      font-weight: bold;
      color: #333333;
      font-size: 28px;
    }

    .tag {
      font-size: 20px;
      margin-left: 14px;
      width: 65px;
      height: 38px;
      background: rgba($color: #f78c00, $alpha: 0.1);
      text-align: center;
      line-height: 38px;
      color: #f78c00;
    }

    .tag2 {
      color: #888888;
      background: rgba($color: #888888, $alpha: 0.1);
    }

    .tag3 {
      color: #61c876;
      background: rgba($color: #61c876, $alpha: 0.1);
    }

    .tag4 {
      color: #f7ad00;
      background: rgba($color: #f7ad00, $alpha: 0.1);
    }
  }

  .center {
    border-left: 1px dashed #bfbfbf;
    margin-left: 10px;
    padding-bottom: 80px;

    .daka-time {
      font-size: 24px;
      font-weight: bold;
      color: #0091ff;
      padding-left: 35px;
      padding-top: 15px;
    }

    .desc {
      font-size: 24px;
      color: #999999;
      padding-left: 35px;
      padding-top: 15px;
    }

    .daka-wrap {
      display: block;
      width: 400px;
      height: 400px;
      margin: 0 auto;
      position: relative;
      color: #fff;

      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .on-time {
        position: absolute;
        top: 150px;
        left: 135px;
        font-size: 30px;
      }

      .time {
        position: absolute;
        top: 190px;
        left: 110px;
        font-size: 48px;
        color: #ffffff;
      }
    }

    .address {
      text-align: center;
      font-size: 24px;
      color: #555555;
    }
  }

  .center2 {
    border-left: none;
  }

  .small-circle {
    width: 20px;
    height: 20px;
    margin-right: 25px;
  }

  .work-time {
    font-weight: bold;
    color: #333333;
    font-size: 28px;
  }
}

.content-pm {
  .top {
    position: relative;

    .line {
      position: absolute;
      top: 33px;
      left: 10px;
      width: 0px;
      height: 163px;
      opacity: 1;
      border-left: 1px dashed #bfbfbf;
    }

    .daka-time {
      font-size: 24px;
      font-weight: bold;
      color: #0091ff;
      padding-left: 48px;
      padding-top: 15px;
    }

    .wifi {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      padding-left: 48px;
      padding-top: 18px;
    }
  }
}

.wqClass {
  .center {
    border-left: 1px dashed #bfbfbf;
    margin-left: 10px;
    padding-bottom: 80px;

    .daka-wrap {
      display: block;
      width: 400px;
      height: 400px;
      margin: 0 auto;
      position: relative;
      color: #fff;

      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .on-time {
        position: absolute;
        top: 150px;
        left: 135px;
        font-size: 30px;
      }

      .time {
        position: absolute;
        top: 190px;
        left: 110px;
        font-size: 48px;
        color: #ffffff;
      }
    }
  }

  .center2 {
    border-left: none;
  }
}

.tranferDiv {
  width: 70%;
  line-height: 100px;
  margin-left: 15%;
  margin-top: 20px;
  text-align: center;
  font-size: 34px;
  background-color: #07c160;
  border-radius: 25px;
}

::v-deep .van-dialog {
  width: 450px;
  height: 640px;
}

::v-deep .van-dialog__header {
  font-size: 32px;
}

.wq-content {
  padding: 40px;

  .time {
    font-size: 24px;
    color: #0091ff;
    margin-bottom: 20px;
  }

  .address {
    margin-bottom: 20px;
  }

  .uploader {
    text-align: center;
    margin: 30px auto 20px;
  }
}

::v-deep .van-field__body {
  padding: 10px;
}

::v-deep .van-empty__image {
  width: 500px;
  height: 500px;
}

::v-deep .van-empty__description {
  font-size: 24px;
}

::v-deep .van-field__control {
  line-height: 36px;
}

::v-deep .van-dialog__cancel,
::v-deep .van-dialog__confirm {
  height: 50px;
  font-size: 14px;
}
</style>
